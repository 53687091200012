//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "TagList",
  components: {
	  ToggleBtn: () => import('../components/toggleBtn.vue')
  },
  props: {
    value: {
      type: [String, Number, Boolean],
      default: ''
	  },
    isCondition: { // false-默认进入筛选条件初始化 
      type: Boolean,
      default: false
    },
    field: { // 字段名
      type: String,
      default: ''
    },
    childField: { // 联动子集选项字段
      type: Array,
      default: () => [],
    },
    dynamicChildField: { // 动态子集联动变化子集
      type: Object,
      default: () => null,
    },
    itemData: {
      type: Object,
      default: () => {},
    },
    options: { // 列表
      type: Array,
      default: () => [],
    },
    isForm: { // 是否是form表单
			type: Boolean,
      default: false
		},
  },
  data() {
    return {
      isOpen: false,
      // input: '',
      initValue: '',
      isShow: false, // 是否展示左侧展开隐藏按钮
      curentField: ''
    }
  },
  watch: {
    options: {
      handler() {
        new Promise(resolve => {
          this.$nextTick(() => {
            const taglistW = this.$refs.taglistDom.offsetWidth + 10
            let childW = 0
            for (const item of this.$refs.taglistDom.children) {
              childW += item.offsetWidth
            }
            resolve(taglistW < childW)
          })
        }).then(falg => {
          this.isShow = falg
        })
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    input: {
      get() {
        return this.initValue
      },
      set(val) {
        let res
        res = this.options.filter(item => {
          return item.no == val
        })
        const childField = val && this.dynamicChildField ? this.dynamicChildField[val] : this.childField
        const { type } = res && res[0]
        this.$emit('click', {...this.itemData, ...res[0], val: res[0].no || '', field: this.field, name: res[0].name, childField: childField, isForm: this.isForm, isCondition: this.isCondition, isOldType: !!type && +type !==  -1  })
      }
    }
  },
  mounted() {
    this.initValue = this.value
    this.curentField = this.field
	  let res
    res = this.options.filter(item => item.no === this.initValue)
    const { type } = res && res[0]
    this.$emit('initFormData', { ...this.itemData, val: res[0].no || '', field: this.field, name: res[0].name, childField: this.childField, isForm: this.isForm, isCondition: this.isCondition, isOldType: !!type && +type !==  -1 })
  },
  methods: {
    tagChange(item) {
      this.initValue = item.no
      this.input = item.no
    }
  },
};
