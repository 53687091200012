//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "SortList",
  props: {
    value: {
      type: [String, Number, Boolean],
      default: null,
    },
    options: {
      type: Array,
      default() {
        return [];
      },
    },
    isCondition: {
      // false-默认进入筛选条件初始化
      type: Boolean,
      default: false,
    },
    isCard: {
      // 是否是form表单
      type: Boolean,
      default: false,
    },
    field: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    cssstyle: {
      // 自定义样式
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      initValue: "",
      curentField: ''
    };
  },
  computed: {
    input: {
      get() {
        return this.initValue;
      },
      set(val) {
        this.initValue = val;
        let res = [];
        res = this.options.filter((item) => item.no === this.initValue);
        if (res && res.length) {
          this.$emit("click", {
            val: res[0].no,
            field: this.field,
            name: res[0].name,
            isCard: this.isCard,
            isCondition: this.isCondition,
          });
        }
      },
    },
  },
  mounted() {
    let res;
    this.initValue = this.value;
    this.curentField = this.field
    res = this.options.filter((item) => item.no === this.initValue);
    if(res && res.length) {
      this.$emit("initFormData", {
        val: res[0].no,
        field: this.field,
        name: res[0].name,
        isCard: this.isCard,
        isCondition: this.isCondition,
      });
    } else {
      this.$emit("initFormData", {
        val: this.initValue,
        field: this.field,
        name: '',
        isCard: this.isCard,
        isCondition: this.isCondition,
      });
    }
    
  },
  methods: {},
};
