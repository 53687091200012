//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "BtnInput",
  props: {
    cssstyle: {
      type: Object,
      default() {
        return {};
      },
    },
    options: {
      type: Array,
      default() {
        return [];
      },
    },
    placeholder: {
      type: String,
      default: "请输入关键字，比如招标名称、采购单位名称等",
    },
    isCondition: {
      // false-默认进入筛选条件初始化
      type: Boolean,
      default: false,
    },
    field: {
      type: String,
      default: "",
    },
    value: {
      type: [String, Number, Boolean],
      default: "",
    },
    showSearchMode: {
      type: Array,
      default() {
        return [];
      },
    },
    isForm: {
      // 是否是form表单
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      input: "",
      curentField: ''
    };
  },
  watch: {
    input(val) {
      //   this.$emit("click", { val: val, field: this.field, isCondition: this.isCondition });
    },
  },
  mounted() {
    this.input = this.value;
    this.curentField = this.field
    this.$emit("initFormData", {
      val: this.input,
      field: this.field,
      isCondition: this.isCondition,
      showSearchMode: this.showSearchMode,
      isForm: this.isForm
    });
  },
  methods: {
    onSearch() {
      this.$emit("click", {
        val: this.input,
        field: this.field,
        isCondition: this.isCondition,
        showSearchMode: this.showSearchMode,
        isForm: this.isForm
      });
    },
  },
};
