//
//
//
//
//
//

export default {
  name: "Checkbox",
  props: {
    value: {
      type: [String, Number, Boolean, Array],
      default: null,
    },
    options: {
      type: Array,
      default() {
        return [];
      },
    },
	isCondition: { // false-默认进入筛选条件初始化 
		type: Boolean,
		default: false
	},
  isCard: {
      // 是否是form表单
      type: Boolean,
      default: false,
    },
    field: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    filterable: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "请选择",
    },
    cssstyle: {
      // 自定义样式
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      initValue: [],
      curentField: ''
    };
  },
  computed: {
    input: {
      get() {
        return this.initValue;
      },
      set(val) {
        this.initValue = val;
        let res = [];
        res = this.options.filter((item) => this.initValue.includes(+item.no))
        if (res && res.length) {
          this.$emit("click", {
            val: res.map(x => x.no),
            field: this.field,
            name: res.map(x => x.name),
            isCard: this.isCard,
			isCondition: this.isCondition
          });
        } else {
			this.$emit("click", {
            val: [],
            field: this.field,
            isCard: this.isCard,
			isCondition: this.isCondition
          });
		}
      },
    },
  },
  watch: {},
  mounted() {
	// this.initValue = this.value
	// this.$emit("initFormData", {
    //   val: this.initValue,
    //   field: this.field
    // });
    // if (this.value && this.value.length) {
      this.initValue = this.value;
      this.curentField = this.field
      const res = this.options.filter((item) => this.initValue.includes(+item.no))
      this.$emit("initFormData", {
        val: res.map(x => x.no),
        field: this.field,
        name: res.map(x => x.name),
        isCard: this.isCard,
        isCondition: this.isCondition
      });
    // }
  },
};
