//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "Select",
  props: {
    value: {
      type: [String, Number, Boolean],
      default: null,
    },
    options: {
      type: Array,
      default() {
        return [];
      },
    },
		isCondition: { // false-默认进入筛选条件初始化 
			type: Boolean,
			default: false
		},
		isForm: { // 是否是form表单
			type: Boolean,
      default: false
		},
    field: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    filterable: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "请选择",
    },
		cssstyle: { // 自定义样式
      type: Object,
      default: () => ({})
    },
  },
  data() {
    return {
      initValue: '',
      curentField: ''
    };
  },
  computed: {
    input: {
      get() {
        return this.initValue;
      },
      set(val) {
        this.initValue = val;
        let res = [];
        res = this.options.filter((item) => item.no === this.initValue);
				if(res && res.length) {
					this.$emit("click", {
						val: res[0].no === '-1' ? '' : res[0].no,
						field: this.field,
						name: res[0].name,
						isForm: this.isForm,
						isCondition: this.isCondition
					});
				} else {
					// 重置
					this.$emit("click", {
						val: '',
						field: this.field,
						name: '不限',
						isForm: this.isForm,
						isCondition: this.isCondition
					});
				}
        
      },
    },
  },
  watch: {},
  mounted() {
		this.initValue = this.value
    this.curentField = this.field
		let res = [];
		res = this.options.filter((item) => item.no === this.initValue);
		if(res && res.length) {
			this.$emit("initFormData", {
				val: res[0].no === '-1' ? '' : res[0].no,
				field: this.field,
				name: res[0].name,
				isForm: this.isForm,
				isCondition: this.isCondition
			});
		} else {
			// 重置
			this.$emit("initFormData", {
				val: '',
				field: this.field,
				name: '不限',
				isForm: this.isForm,
				isCondition: this.isCondition
			});
		}
    // let res;
    // if (this.value) {
    //   this.initValue = this.value;
    //   res = this.options.filter((item) => item.no === this.initValue);
    //   this.$emit("click", {
    //     val: res[0].no === '-1' ? '' : res[0].no,
    //     field: this.field,
    //     name: res[0].name,
		// 		isForm: this.isForm
    //   });
    // }
  },
};
