//
//
//
//
//
//
//
//
//
//
//
//
//
//

import env from "../../../env.js";
export default {
  props: {
    isClose: {
      type: Boolean,
      default: true
    },
    isMore: {
      type: Boolean,
      default: true
    },
    isLoadData: {
      type: Boolean,
      default: true
    },
    goData: {
      type: Object,
      default: () => ({ text: "商品", url: "" })
    },
    menuIndex: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {
    refreshData() {
      this.$bus.$emit("scrollCurent", { index: this.menuIndex });
      this.$emit("refreshData");
    },
    loadMore() {
      this.$emit("loadMore");
    },
    goPage() {
      let url = ''
      if(this.goData.isSupermarke){
        url = this.goData.url
      } else {
        url = env[process.env.environment][this.goData.url];
      }
      if (url) {
        window.open(url);
      }
    }
  }
};
