import TagList from './TagList.vue'
import Select from './Select.vue'
import Checkbox from './Checkbox.vue'
import SortList from './SortList.vue'
import BtnInput from './BtnInput.vue'

export default {
	TagList,
	Select,
	Checkbox,
	SortList,
	BtnInput
}