//
//
//
//
//
//
//

export default {
  model: {
	prop: 'isOpen',
	event: 'change'
  },
  props: {
	isOpen: {
		type: Boolean,
		default: false
	}
  },
  data () {
	return {}
  },
  mounted () {

  },
  methods: {
	// 展开收起切换
	toggle() {
		this.$emit('change', !this.isOpen)
	}
  }
}
